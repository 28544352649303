import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import * as service from "./nccDocumentVerificationServices";
import { Col, Row } from "react-bootstrap";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { log } from "../../helper/helper";
import "./nccDocumentVerification.scss";
import config from "../../config.json";
import Input from "../common/input";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { Divider } from "antd";

function NccDocumentVerificationDetail(props) {
  const [isLoading, setLoading] = useState(true);
  const [isPoliceCheck, setIsPoliceCheck] = useState();
  const [rider, setRider] = useState();
  const [docUrl, setDocUrl] = useState();

  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");

  const [birthday, setBirthday] = useState("");
  const [birthdayError, setBirthdayError] = useState("");

  const [documentDateIssue, setDocumentDateIssue] = useState("");
  const [documentDateIssueError, setDocumentDateIssueError] = useState("");

  const [codeOne, setCodeOne] = useState("");
  const [codeOneError, setCodeOneError] = useState("");

  const [codeSecond, setCodeSecond] = useState("");
  const [codeSecondError, setCodeSecondError] = useState("");
  const [textareaMoreInfo, setTextareaMoreInfo] = useState("");
  const [isMoreInfoBtnDisable, setMoreInfoBtnDisable] = useState(true);

  const [dco, setDco] = useState();
  const [moreInfoReasonRadio, setMoreInfoReasonRadio] = useState();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });

    const ImageDownloader = (() => {
      const storage = getStorage();
      return {
        getImage: (avatar) => {
          const starsRef = ref(storage, avatar);
          return getDownloadURL(starsRef)
            .then((url) => {
              return url;
            })
            .catch((error) => {
              log(`Error downloading ${avatar}:`, error);
            });
        },
      };
    })();

    if (props.location.state === undefined) {
      return props.history.push("/home/nccDocumentVerification");
    }
    setRider(props.location.state.rider);
    setIsPoliceCheck(props.location.state.isPoliceCheck);
    const docAddress = props.location.state.isPoliceCheck
      ? props.location.state.rider.ncc.police_doc_url
      : props.location.state.rider.ncc.right_work_doc_url;

    ImageDownloader.getImage(docAddress).then((url) => {
      setDocUrl(url);
      setLoading(false);
    });
  }, []);

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleMoreInfoReasonRadio = (value) => {
    setMoreInfoReasonRadio(value);
    if (textareaMoreInfo.replace(/\s/g, "").length > 1) {
      setMoreInfoBtnDisable(false);
    } else {
      setMoreInfoBtnDisable(true);
    }
  };

  const handleVerifyRequest = async () => {
    setFullNameError("");
    if (fullName.replace(/\s/g, "").length < 2) {
      return setFullNameError(
        "Minimum 2 letters (English Letter(s) and Number(s)"
      );
    }

    setBirthdayError("");
    if (birthday === "") {
      return setBirthdayError("Pick a date to continue");
    }

    setCodeOneError("");
    if (codeOne.replace(/\s/g, "").length < 2) {
      return setCodeOneError(
        "Minimum 2 letters (English Letter(s) and Number(s)"
      );
    }

    setCodeSecondError("");
    if (codeSecond.replace(/\s/g, "").length < 2) {
      return setCodeSecondError(
        "Minimum 2 letters (English Letter(s) and Number(s)"
      );
    }

    setDocumentDateIssueError("");
    if (documentDateIssue === "") {
      return setDocumentDateIssue("Document date issue is not valid");
    }

    const sortedDateOfIssue = new Date(documentDateIssue);
    const momentDateOfIssue = Moment(sortedDateOfIssue).format("YYYY-MM-DD");

    const sortedDateOfBirth = new Date(birthday);
    const momentDateOfBirth = Moment(sortedDateOfBirth).format("YYYY-MM-DD");

    if (isPoliceCheck) {
      if (dco === undefined) {
        return toast.error("Select DCO or NDCO");
      }
      const nccResult = dco === 0 ? "DCO" : "NDCO";

      setLoading(true);
      try {
        const result = await service.adminRiderNccReviewVerifyPoliceCheck(
          rider.rider_uid,
          momentDateOfIssue,
          fullName,
          momentDateOfBirth,
          codeOne,
          codeSecond,
          nccResult
        );
        log(result);

        if (result) {
          switch (result.data.code) {
            case 200:
              toast.success(result.data.message);
              setTimeout(function () {
                props.history.push("/home/nccDocumentVerification");
                setLoading(false);
              }, 500);
              return;
            case 401:
              setLoading(false);
              toast.error(result.data.message);
              return this.props.history.replace("/login");
            default:
              setLoading(false);
              return toast.error(result.data.message);
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    } else {
      setLoading(true);
      try {
        const result = await service.adminRiderNccReviewVerifyRightToWork(
          rider.rider_uid,
          momentDateOfIssue,
          fullName,
          momentDateOfBirth,
          codeOne,
          codeSecond
        );
        log(result);

        if (result) {
          switch (result.data.code) {
            case 200:
              toast.success(result.data.message);
              setTimeout(function () {
                props.history.push("/home/nccDocumentVerification");
                setLoading(false);
              }, 500);
              return;
            case 401:
              setLoading(false);
              toast.error(result.data.message);
              return this.props.history.replace("/login");
            default:
              setLoading(false);
              return toast.error(result.data.message);
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    }
  };

  const handleMoreInformationRequest = async () => {
    var reason = "";
    switch (moreInfoReasonRadio) {
      case 0:
        reason = "Document Issue Date Not Accepted";
        break;
      case 1:
        reason = "Personal Details Mismatch";
        break;
      case 2:
        reason =
          "Attached file is Wrong, Invalid or from Not Accepted Provider";
        break;
      default:
        break;
    }
    var selectedService = isPoliceCheck
      ? service.adminRiderNccReviewFurtherInvestigationPoliceCheck
      : service.adminRiderNccReviewFurtherInvestigationRightToWork;

    setLoading(true);
    try {
      const result = await selectedService(
        rider.rider_uid,
        textareaMoreInfo,
        reason
      );
      log(result);

      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            setTimeout(function () {
              props.history.push("/home/nccDocumentVerification");
              setLoading(false);
            }, 500);
            return;
          case 401:
            setLoading(false);
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          default:
            setLoading(false);
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const handleRequestNCCPageUrl = () => {
    const webViewURL = window.localStorage.getItem(config.nccVerifyPageUrl);
    var win = window.open(webViewURL, "_blank");
    win.focus();
  };

  return (
    <div className="search-list data-server-list ncc-verification">
      <ToastContainer />
      {renderSpinner()}
      <div className="container">
        <h5 style={{ margin: "20px 0" }}>
          {isPoliceCheck
            ? "Police Check Verification"
            : " Right to Work Verification"}
        </h5>
        <Row>
          <Col md="6" sm="12" xs="12">
            <div className="pdf-show">
              <iframe src={docUrl} title="pdf" />
            </div>
          </Col>
          <Col md="6" sm="12" xs="12">
            <div className="web-view-side-show">
              {/* <iframe src={webViewURL} title="pdf" /> */}
              <Row>
                <Col md="6" sm="12" xs="12">
                  <Input
                    label="Full Name:"
                    type="String"
                    name="fullName"
                    value={fullName}
                    error={fullNameError}
                    onChange={(e) => {
                      setFullName(e.target.value);
                      if (e.target.value.replace(/\s/g, "").length > 1) {
                        setFullNameError("");
                      } else {
                        setFullNameError("Minimum 2 letters (English Only)");
                      }
                    }}
                  />
                </Col>
                <Col md="6" sm="12" xs="12">
                  <div className="form-group">
                    <label htmlFor="dateTime">Birthday:</label>
                    <DatePicker
                      selected={birthday}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => setBirthday(date)}
                      className="form-control"
                      error={birthdayError}
                      // maxDate={maxdate}
                    />
                  </div>
                </Col>
                <Col md="6" sm="12" xs="12">
                  <Input
                    label="Code 1:"
                    type="String"
                    name="codeOne"
                    value={codeOne}
                    error={codeOneError}
                    onChange={(e) => {
                      setCodeOne(e.target.value.toUpperCase());
                      if (e.target.value.replace(/\s/g, "").length > 1) {
                        setCodeOneError("");
                      } else {
                        setCodeOneError("Minimum 2 letters");
                      }
                    }}
                  />
                </Col>
                <Col md="6" sm="12" xs="12">
                  <Input
                    label="Code 2:"
                    type="String"
                    name="codeSecond"
                    value={codeSecond}
                    error={codeSecondError}
                    onChange={(e) => {
                      setCodeSecond(e.target.value.toUpperCase());
                      if (e.target.value.replace(/\s/g, "").length > 1) {
                        setCodeSecondError("");
                      } else {
                        setCodeSecondError("Minimum 2 letters ");
                      }
                    }}
                  />
                </Col>
                <Col md={isPoliceCheck ? "6" : "12"} sm="12" xs="12">
                  <div className="form-group">
                    <label htmlFor="dateTime">Document Date issue:</label>
                    <DatePicker
                      selected={documentDateIssue}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => setDocumentDateIssue(date)}
                      className="form-control"
                      error={documentDateIssueError}
                      // maxDate={maxdate}
                    />
                  </div>
                </Col>
                {isPoliceCheck && (
                  <Col md="6" sm="12" xs="12">
                    <div className="form-group radio-ncc">
                      <input
                        type="radio"
                        value={dco === 0}
                        name="dco"
                        checked={dco === 0}
                        onChange={() => setDco(0)}
                      />{" "}
                      <label htmlFor="">DCO</label>
                      <input
                        type="radio"
                        value={dco === 1}
                        name="dco"
                        checked={dco === 1}
                        onChange={() => setDco(1)}
                      />{" "}
                      <label htmlFor="">NDCO</label>
                    </div>
                  </Col>
                )}
                <Col md="12" sm="12" xs="12">
                  <div
                    className="ncc-group-btn-reason"
                    style={{ margin: "20px 0" }}
                  >
                    <button onClick={() => handleVerifyRequest()}>
                      Verify
                    </button>
                    <button
                      style={{ marginLeft: 20 }}
                      onClick={() => handleRequestNCCPageUrl()}
                    >
                      Open NCC Verify Page
                    </button>
                  </div>
                </Col>
                <Divider>Request More Information?</Divider>
                <Col md="12" sm="12" xs="12">
                  <div className="form-group radio-ncc-reason">
                    <div className="title" style={{ margin: "40px 0 10px " }}>
                      More Information Request reason:
                    </div>
                    <input
                      type="radio"
                      value={moreInfoReasonRadio === 0}
                      name="moreInfoReasonRadio"
                      checked={moreInfoReasonRadio === 0}
                      onChange={() => handleMoreInfoReasonRadio(0)}
                    />{" "}
                    <label htmlFor="">Document Issue Date Not Accepted</label>
                    <br />
                    <input
                      type="radio"
                      value={moreInfoReasonRadio === 1}
                      name="moreInfoReasonRadio"
                      checked={moreInfoReasonRadio === 1}
                      onChange={() => handleMoreInfoReasonRadio(1)}
                    />{" "}
                    <label htmlFor="">Personal Details Mismatch</label>
                    <br />
                    <input
                      type="radio"
                      value={moreInfoReasonRadio === 2}
                      name="moreInfoReasonRadio"
                      checked={moreInfoReasonRadio === 2}
                      onChange={() => handleMoreInfoReasonRadio(2)}
                    />{" "}
                    <label htmlFor="">
                      Attached file is Wrong, Invalid or from Not Accepted
                      Provider
                    </label>
                  </div>
                </Col>
                <Col md="12" sm="12" xs="12">
                  <div className="form-group">
                    <div className="title" style={{ margin: "20px 0 0 0 " }}>
                      Admin note:
                    </div>
                    <textarea
                      className="textarea-info"
                      name="moreInfo"
                      id="moreInfo"
                      cols="30"
                      rows="10"
                      value={textareaMoreInfo}
                      onChange={(e) => {
                        const value = e.target.value;
                        setTextareaMoreInfo(value);
                        if (value.replace(/\s/g, "").length > 1) {
                          if (moreInfoReasonRadio !== undefined) {
                            setMoreInfoBtnDisable(false);
                          } else {
                            setMoreInfoBtnDisable(true);
                          }
                        } else {
                          setMoreInfoBtnDisable(true);
                        }
                      }}
                    ></textarea>
                  </div>
                </Col>
                <Col md="12" sm="12" xs="12">
                  <div
                    className="ncc-group-btn-reason"
                    style={{ margin: "20px 0" }}
                  >
                    <button
                      onClick={() => handleMoreInformationRequest()}
                      disabled={isMoreInfoBtnDisable}
                    >
                      Request More Information
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default NccDocumentVerificationDetail;
