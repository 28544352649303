import { getFunctions, httpsCallable } from "firebase/functions";
import config from "./../../config.json";

export function adminRiderInboxForNcc() {
  const functions = getFunctions();
  const ncc = httpsCallable(functions, config.adminRiderInboxForNcc);
  return ncc();
}

export function adminRiderNccReviewVerifyPoliceCheck(
  uid,
  issueDate,
  fullName,
  birthDate,
  CodeOne,
  codeSecond,
  nccResult
) {
  const data = {
    uid: uid,
    police_check_issue_date: issueDate,
    full_name: fullName,
    birth_date: birthDate,
    police_check_verification_code_1: CodeOne,
    police_check_verification_code_2: codeSecond,
    ncc_result: nccResult,
  };
  const functions = getFunctions();
  const verify = httpsCallable(
    functions,
    config.adminRiderNccReviewVerifyPoliceCheck
  );
  return verify(data);
}

export function adminRiderNccReviewVerifyRightToWork(
  uid,
  issueDate,
  fullName,
  birthDate,
  CodeOne,
  codeSecond
) {
  const data = {
    uid: uid,
    right_to_work_issue_date: issueDate,
    full_name: fullName,
    birth_date: birthDate,
    right_to_work_verification_code_1: CodeOne,
    right_to_work_verification_code_2: codeSecond,
  };
  const functions = getFunctions();
  const verify = httpsCallable(
    functions,
    config.adminRiderNccReviewVerifyRightToWork
  );
  return verify(data);
}

export function adminRiderNccReviewFurtherInvestigationPoliceCheck(
  uid,
  resultNote,
  notAcceptableReasonn
) {
  const data = {
    uid: uid,
    result_note: resultNote,
    ncc_police_check_report_not_acceptable_reason: notAcceptableReasonn,
  };
  const functions = getFunctions();
  const furtherInvestigation = httpsCallable(
    functions,
    config.adminRiderNccReviewFurtherInvestigationPoliceCheck
  );
  return furtherInvestigation(data);
}

export function adminRiderNccReviewFurtherInvestigationRightToWork(
  uid,
  resultNote,
  notAcceptableReason
) {
  const data = {
    uid: uid,
    result_note: resultNote,
    ncc_right_to_work_report_not_acceptable_reason: notAcceptableReason,
  };
  const functions = getFunctions();
  const furtherInvestigation = httpsCallable(
    functions,
    config.adminRiderNccReviewFurtherInvestigationRightToWork
  );
  return furtherInvestigation(data);
}
