import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import * as service from "./nccDocumentVerificationServices";
import "./nccDocumentVerification.scss";

function NccDocumentVerification(props) {
  const [isLoading, setLoading] = useState(false);
  const [nccList, setNccList] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
      getAllNCCInfo();
    });
  }, []);

  const getAllNCCInfo = async () => {
    setLoading(true);
    try {
      const result = await service.adminRiderInboxForNcc();
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            if (result.data.rider.length > 0) {
              var data = result.data.rider;
              const sortedData = data.sort(
                (a, b) =>
                  new Date(a.ncc.police_check_issue_date) -
                  new Date(b.ncc.police_check_issue_date)
              );

              setNccList(sortedData);
            }

            return;
          case 201:
            return;
          case 401:
          case 410:
          case 499:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handlePoliceCheckStatus = (rider) => {
    if (
      rider.ncc_police_check_doc_reviewed &&
      !rider.ncc_police_check_doc_verified
    ) {
      return <div className="ncc-riviewed">reviewed</div>;
    } else if (
      rider.ncc_police_check_doc_reviewed &&
      rider.ncc_police_check_doc_verified
    ) {
      return <div className="ncc-verified">verified</div>;
    } else {
      return (
        <button
          className="search-view"
          onClick={() =>
            props.history.push(
              "/home/nccDocumentVerification/nccDocumentVerificationDetail",
              {
                rider: rider,
                isPoliceCheck: true,
              }
            )
          }
        >
          Review
        </button>
      );
    }
  };
  const handleRightToWorkStatus = (rider) => {
    if (
      rider.ncc_right_to_work_doc_reviewed &&
      !rider.ncc_right_to_work_doc_verified
    ) {
      return <div className="ncc-riviewed">reviewed</div>;
    } else if (
      rider.ncc_right_to_work_doc_reviewed &&
      rider.ncc_right_to_work_doc_verified
    ) {
      return <div className="ncc-verified">verified</div>;
    } else {
      return (
        <button
          className="search-view"
          onClick={() =>
            props.history.push(
              "/home/nccDocumentVerification/nccDocumentVerificationDetail",
              {
                rider: rider,
                isPoliceCheck: false,
              }
            )
          }
        >
          Review
        </button>
      );
    }
  };

  return (
    <div className="search-list data-server-list ncc-verification">
      <ToastContainer />
      {renderSpinner()}
      <h6>
        Found {nccList.length} {nccList.length > 1 ? "result" : "results"}
      </h6>

      <table className="table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Police Check Date</th>
            <th>Police Check Status</th>
            <th>Work Right Date</th>
            <th>Work Right Status</th>
          </tr>
        </thead>
        <tbody>
          {nccList.map((rider) => (
            <tr key={rider.rider_uid}>
              <td>{rider.given_name}</td>
              <td>{rider.ncc.police_check_issue_date}</td>
              <td>{handlePoliceCheckStatus(rider)}</td>
              <td>{rider.ncc.right_to_work_issue_date}</td>
              <td>{handleRightToWorkStatus(rider)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default NccDocumentVerification;
