import { getValue, fetchAndActivate } from "firebase/remote-config";
import { remoteConfig } from "./index";
import config from "./config.json";
import { log } from "./helper/helper";


export const getRemoteConfigData = async () => {
  try {
    remoteConfig.settings.minimumFetchIntervalMillis = 0;
    await fetchAndActivate(remoteConfig).then(() => {
      const bearerCloudfunctionsIsRunning = getValue(remoteConfig, config.bearerCloudfunctionsIsRunning).asString();
      window.localStorage.setItem(config.bearerCloudfunctionsIsRunning, bearerCloudfunctionsIsRunning);
      log("bearerCloudfunctionsIsRunning:" + bearerCloudfunctionsIsRunning);
      // -----------------------------------------------------------------------------------------------------------
      const bearerCloudfunctionsStatusMessageText = getValue(remoteConfig, config.bearerCloudfunctionsStatusMessageText).asString();
      window.localStorage.setItem(config.bearerCloudfunctionsStatusMessageText, bearerCloudfunctionsStatusMessageText);
      log("bearerCloudfunctionsStatusMessageText:" + bearerCloudfunctionsStatusMessageText);
      // -----------------------------------------------------------------------------------------------------------
      const bearerAdminpanelIsRunning = getValue(remoteConfig, config.bearerAdminpanelIsRunning).asString();
      window.localStorage.setItem(config.bearerAdminpanelIsRunning, bearerAdminpanelIsRunning);
      log("bearerAdminpanelIsRunning:" + bearerAdminpanelIsRunning);
      // -----------------------------------------------------------------------------------------------------------
      const bearerAdminpanelStatusMessageText = getValue(remoteConfig, config.bearerAdminpanelStatusMessageText).asString();
      window.localStorage.setItem(config.bearerAdminpanelStatusMessageText, bearerAdminpanelStatusMessageText);
      log("bearerAdminpanelStatusMessageText:" + bearerAdminpanelStatusMessageText);
      // -----------------------------------------------------------------------------------------------------------
      const bearerAdminpanelStatusMessageButtonAction = getValue(remoteConfig, config.bearerAdminpanelStatusMessageButtonAction).asString();
      window.localStorage.setItem(config.bearerAdminpanelStatusMessageButtonAction, bearerAdminpanelStatusMessageButtonAction);
      log("bearerAdminpanelStatusMessageButtonAction:" + bearerAdminpanelStatusMessageButtonAction);
      // -----------------------------------------------------------------------------------------------------------
      const bearerAdminpanelActionUrl = getValue(remoteConfig, config.bearerAdminpanelActionUrl).asString();
      window.localStorage.setItem(config.bearerAdminpanelActionUrl, bearerAdminpanelActionUrl);
      log("bearerAdminpanelActionUrl:" + bearerAdminpanelActionUrl);
      // -----------------------------------------------------------------------------------------------------------
      const bearerAdminPanelWebSessionValidityMinutes = getValue(remoteConfig, config.bearerAdminPanelWebSessionValidityMinutes).asString();
      window.localStorage.setItem(config.bearerAdminPanelWebSessionValidityMinutes, bearerAdminPanelWebSessionValidityMinutes);
      log("bearerAdminPanelWebSessionValidityMinutes:" + bearerAdminPanelWebSessionValidityMinutes);
      // -----------------------------------------------------------------------------------------------------------
      const environmentCode = getValue(remoteConfig, config.environmentCode).asString();
      window.localStorage.setItem(config.environmentCode, environmentCode);
      log("environmentCode:" + environmentCode);
      // -----------------------------------------------------------------------------------------------------------
      // -----------------------------------------------------------------------------------------------------------
      const nccVerifyPageUrl = getValue(remoteConfig, config.nccVerifyPageUrl).asString();
      window.localStorage.setItem(config.nccVerifyPageUrl, nccVerifyPageUrl);
      log("nccVerifyPageUrl:" + nccVerifyPageUrl);
      // -----------------------------------------------------------------------------------------------------------
    });
    return true
  } catch (err) {
    return false
  }
};
