import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import NavBar from "../common/navBar";
import "./home.scss";
import { getAuth, signOut } from "firebase/auth";
import { Statistic } from "antd";
import FindCustomer from "../findCustomer/findCustomer";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../dashboard/dashboard";
import FindRider from "../findRider/findRider";
import CustomerList from "../findCustomer/customerList";
import CustomerSingle from "../findCustomer/customerSingle";
import RiderList from "../findRider/riderList";
import RiderSingle from "../findRider/riderSingle";
import FindTransport from "../findTransport/findTransport";
import FindTransportSingle from "../findTransport/findTransportSingle";
import ShowAllTransports from "../global/showAllTransports";
import ShowActiveTransports from "../global/showActiveTransports";
import FindMotorbike from "../motorbike/findMotorbike";
import MotorbikeLists from "../motorbikeList/motorbikeLists";
import MotorbikeSingle from "../motorbike/motorbikeSingle";
import CouponList from "../coupons/couponList";
import CouponSingle from "../coupons/couponSingle";
import ModesOfTransports from "../global/modesOfTransports";
import PricingObject from "../pricingObject/pricingObject";
import ParcelList from "../parcel/parcelList";
import Notification from "../notification/notification";
import BusinessCustomer from "../businessCustomer/businessCustomer";
import SendSMS from "../sendSMS/sendSMS";
import SendEmail from "../sendEmail/sendEmail";
import UncompletedRider from "../findRider/uncompletedRider";
import ParcelSingle from "../parcel/parcelSingle";
import AllRiders from "../riderList/allRiders";
import AvatarConfirmation from "../riderList/avatarConfirmation";
import LegalDetail from "../riderList/legalDetail";
import RightToWork from "../riderList/rightToWork";
import PoliceCheck from "../riderList/policeCheck";
import MotorbikeList from "../motorbike/motorbikeList";
import ParcelHistory from "../parcel/parcelHistory";
import ParcelHistorySingle from "../parcel/parcelHistorySingle";
import PricinghistoryList from "../pricingObject/pricinghistoryList";
import PricingHistorySingle from "../pricingObject/pricingHistorySingle";
import CouponHistorySingle from "../coupons/couponHistorySingle";
import CouponHistoryList from "../coupons/couponHistoryList";
import MotorbikeHistorySingle from "../motorbike/motorbikeHistorySingle";
import MotorbikeHistoryList from "../motorbike/motorbikeHistoryList";
import WebEnquiries from "../webEnquiries/webEnquiries";
import { appCheckToken } from "../../index";
import { getToken } from "firebase/app-check";
import Spinner from "../common/spinner";
import config from "../../config.json";
import incompleteRiderList from "../incompleteRiders/incompleteRiderList";
import AllCustomer from "../allCustomer/allCustomer";
import OnlineBearer from "../onlineBearer/onlineBearer";
import OnlineBearerList from "../onlineBearer/onlineBearerList";
import OnlineTimeCalculatorTimeSheet from "../findRider/components/OnlineTimeCalculatorTimeSheet";
import { useJsApiLoader } from "@react-google-maps/api";
import AssignmentTransports from "../assignmentTransports/assignmentTransports";
import AutoAssignedTransportList from "../autoAssignedTransportList/autoAssignedTransportList";
import BatchRiderBonusUpdate from "../batchRiderBonusUpdate /batchRiderBonusUpdate";
import NCCDocumentVerification from "../nccDocumentVerification/nccDocumentVerification";
import NccDocumentVerificationDetail from "../nccDocumentVerification/nssDocumentVerificationDetail";
const placesLibrary = ["places"];

function Home(props) {
  const name = localStorage.getItem("firstName");
  const isLogin = Boolean(localStorage.getItem("isLoginSuccessfully"));
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [countDowntimer, setCountDowntimer] = useState();
  const { Countdown } = Statistic;
  var environment = window.localStorage.getItem(config.environmentCode);

  const onCountDownFinish = () => {
    if (environment !== "DEVELOPMENT") {
      const auth = getAuth();
      signOut(auth).then(() => {
        return props.history.replace("/login");
      });
    }
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    libraries: placesLibrary,
  });

  useEffect(() => {
    if (isLogin === false) {
      const auth = getAuth();
      signOut(auth).then(() => {
        return props.history.replace("/login");
      });
    } else {
      getAppCheckToken();
    }
  }, []);

  const signout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      return props.history.replace("/login");
    });
  };
  const getAppCheckToken = async () => {
    try {
      const { token } = await getToken(appCheckToken);
      if (token !== "") {
        setShow(true);
        setLoading(false);
      } else {
        return props.history.replace("/login");
      }
    } catch (err) {
      console.error(err);
      return props.history.replace("/login");
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  return (
    <>
      {renderSpinner()}
      {show && isLoaded ? (
        <div className="row">
          {/* <div className="sidebar-menu col-lg-3 col-md-3 col-sm-12 col-xs-12"> */}
          <div className="sidebar">
            <NavBar props={props} />
          </div>
          {/* </div> */}
          {/* <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12"> */}
          <div className="container-fluid main-data-section">
            <div className="row">
              <div className="col-lg-12">
                <Countdown
                  className={
                    countDowntimer < 1903927
                      ? "red count-down-session"
                      : "black count-down-session"
                  }
                  value={
                    new Date(localStorage.getItem("startTime")).getTime() +
                    1000 *
                      60 *
                      Number(
                        localStorage.getItem(
                          config.bearerAdminPanelWebSessionValidityMinutes
                        )
                      )
                  }
                  onFinish={onCountDownFinish}
                />
                <div className="topbar">
                  <div className="info">
                    <span>Hi {name}</span>
                    <span className="logout">
                      <button onClick={() => signout()}>Logout</button>
                    </span>
                  </div>
                  <div className="line"></div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="content">
                  <Switch>
                    <Route path="/home/webEnquiries" component={WebEnquiries} />
                    <Route
                      path="/home/allRiders/policeCheck"
                      component={PoliceCheck}
                    />
                    <Route
                      path="/home/allRiders/rightToWork"
                      component={RightToWork}
                    />
                    <Route
                      path="/home/allRiders/legalDetail"
                      component={LegalDetail}
                    />
                    <Route
                      path="/home/allRiders/avatar"
                      component={AvatarConfirmation}
                    />
                    <Route
                      path="/home/allRiders/riderSingle"
                      component={RiderSingle}
                    />
                    <Route
                      path="/home/allRiders/uncompletedRider"
                      component={UncompletedRider}
                    />
                    <Route path="/home/allRiders" component={AllRiders} />
                    <Route
                      path="/home/allNewRiders"
                      component={incompleteRiderList}
                    />

                    <Route
                      path="/home/motorbikeLists"
                      component={MotorbikeLists}
                    />
                    <Route
                      path="/home/nccDocumentVerification/nccDocumentVerificationDetail"
                      component={NccDocumentVerificationDetail}
                    />
                    <Route
                      path="/home/nccDocumentVerification"
                      component={NCCDocumentVerification}
                    />
                    <Route
                      path="/home/couponList/couponSingle"
                      component={CouponSingle}
                    />
                    <Route
                      path="/home/findCustomer/customerList/customerSingle"
                      component={CustomerSingle}
                    />
                    <Route
                      path="/home/findCustomer/customerList"
                      component={CustomerList}
                    />
                    <Route
                      path="/home/findRider/riderList/riderSingle/onlineTimesheet"
                      component={OnlineTimeCalculatorTimeSheet}
                    />
                    <Route
                      path="/home/findRider/riderList/riderSingle"
                      component={RiderSingle}
                    />
                    <Route
                      path="/home/findRider/riderList/uncompletedRider"
                      component={UncompletedRider}
                    />

                    <Route
                      path="/home/findRider/riderList"
                      component={RiderList}
                    />
                    <Route
                      path="/home/findTransport/findTransportSingle"
                      component={FindTransportSingle}
                    />
                    <Route
                      path="/home/findMotorbike/motorbikeList/motorbike/rider"
                      component={RiderSingle}
                    />
                    <Route
                      path="/home/findMotorbike/motorbikeList/history/single"
                      component={MotorbikeHistorySingle}
                    />
                    <Route
                      path="/home/findMotorbike/motorbikeList/motorbike"
                      component={MotorbikeSingle}
                    />
                    <Route
                      path="/home/findMotorbike/motorbikeList/history"
                      component={MotorbikeHistoryList}
                    />
                    <Route
                      path="/home/findMotorbike/motorbikeList"
                      component={MotorbikeList}
                    />
                    <Route
                      path="/home/modesOfTransport/motorbike"
                      component={MotorbikeSingle}
                    />
                    <Route
                      path="/home/parcelList/parcelHistory/single"
                      component={ParcelHistorySingle}
                    />
                    <Route
                      path="/home/parcelList/parcelHistory"
                      component={ParcelHistory}
                    />
                    <Route
                      path="/home/parcelList/parcel"
                      component={ParcelSingle}
                    />
                    <Route path="/home/parcelList" component={ParcelList} />
                    <Route
                      path="/home/pricingObject/history/single"
                      component={PricingHistorySingle}
                    />
                    <Route
                      path="/home/pricingObject/history"
                      component={PricinghistoryList}
                    />
                    <Route
                      path="/home/pricingObject"
                      component={PricingObject}
                    />
                    <Route path="/home/findCustomer" component={FindCustomer} />
                    <Route path="/home/allCustomer" component={AllCustomer} />
                    <Route
                      path="/home/findMotorbike"
                      component={FindMotorbike}
                    />
                    <Route exact path="/home/findRider" component={FindRider} />
                    <Route
                      path="/home/findTransport"
                      component={FindTransport}
                    />
                    <Route
                      path="/home/assignmentTransports"
                      component={AssignmentTransports}
                    />
                    <Route
                      path="/home/AutoAssignedTransportList"
                      component={AutoAssignedTransportList}
                    />
                    <Route
                      path="/home/BatchRiderBonusUpdate"
                      component={BatchRiderBonusUpdate}
                    />
                    <Route
                      path="/home/couponList/history/single"
                      component={CouponHistorySingle}
                    />
                    <Route
                      path="/home/couponList/history"
                      component={CouponHistoryList}
                    />
                    <Route path="/home/couponList" component={CouponList} />
                    <Route path="/home/dashboard" component={Dashboard} />
                    <Route path="/home/notification" component={Notification} />
                    <Route path="/home/sendSMS" component={SendSMS} />
                    <Route path="/home/sendEmail" component={SendEmail} />
                    <Route
                      path="/home/businessCustomer"
                      component={BusinessCustomer}
                    />

                    <Route
                      path="/home/modesOfTransport"
                      component={ModesOfTransports}
                    />
                    <Route
                      path="/home/showAllTransports"
                      component={ShowAllTransports}
                    />
                    <Route
                      path="/home/showActiveTransports"
                      component={ShowActiveTransports}
                    />
                    <Route
                      path="/home/transportSingle"
                      component={FindTransportSingle}
                    />
                    <Route
                      path="/home/onlineBearers/list"
                      component={OnlineBearerList}
                    />
                    <Route
                      path="/home/onlineBearers"
                      component={OnlineBearer}
                    />
                  </Switch>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Home;
